/* .searchInput.ant-input:placeholder-shown{
    font: normal normal normal 14px/19px Open Sans;
    color: #858282;
} */
.ant-table-column-title{
    font-family:'Inter';
    font-weight:700;
    font-style: normal;
}
.ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-family: "Inter";
    color: #868B8F;
    line-height: 17px;
}
table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 13px 5px 13px 5px;
  left: -5px;
}
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
  .ant-table.ant-table-small {
    font-size: 13px;
}
.ant-btn:hover{
  color: #192228;
}