#logo-img1 {
	width: 78%;
	max-width: 95px;
}

.firstMenu.ant-menu-horizontal > .ant-menu-item::after,
.firstMenu.ant-menu-horizontal > .ant-menu-item:hover,
.firstMenu.ant-menu-horizontal > .ant-menu-item-selected,
.firstMenu.ant-menu-horizontal > .ant-menu-item-active
/* .firstMenu.ant-menu-horizontal > .ant-menu-item-open { */
 { border-bottom: none !important;
  transition: none !important;
  background-color: transparent;
  color: white;
}
/* .firstMenu.ant-menu-horizontal > .ant-menu-item::after{
	content: '▼';
    position: relative;
    width: 0;
    height: 75px;
    left: 40%;
    top: -39px;
    border-left: 10px outset transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
} */
.ant-menu{
	box-shadow: none;
	/* font: normal normal normal 14px/19px Open Sans; */
}
/* .secondMenu.ant-menu-horizontal > .ant-menu-item::after, */
.secondMenu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-item-selected::after,
/* .secondMenu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active */
.secondMenu.ant-menu-horizontal>.ant-menu-item:hover
/* .secondMenu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, */
/* .secondMenu.ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child */
{
	color: #3F3F3F;
	transition: none !important;
	/* border-bottom: none !important; */
	/* border-bottom:2px solid #3F3F3F !important; */
	
	/* border-bottom: none !important; */
}
/* .ant-menu-item:hover::after {
  border-bottom: none !important;
} */

/* .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
	border-bottom: 2px solid #3F3F3F !important;
} */
/* .global-sel.ant-select:not(.ant-select-customize-input) .ant-select-selector{
	background-color: #D9D7D7;
} */

.ant-menu-horizontal{
  border-bottom: none !important;
}